import { useState } from 'react';
import Select, { components } from 'react-select';
import styles from './style.module.scss';
import ChangesLog from '../ChangesLog';
import Clear from '../../../public/images/clear.svg';

export default function SelectLog({
  id,
  value,
  instanceId,
  prevValue,
  data,
  onChange,
  placeholder,
  customStyles,
  wasChanged,
  disabled,
  approved,
  changed,
  dismiss,
  onInputChange,
  noOptionsMessage = 'No options',
  isLoading = false,
  formatOptionLabel,
  clearable,
  onClear,
  onMenuOpen,
  onMenuClose,
  isSearchable = true,
  hasColumns = false,
  customHeight = '54px',
  menuPortalTarget,
  menuPlacement,
  flag
}) {
  const [showLog, setShowLog] = useState(false);

  const approvedProps = { bdWidth: '2px', bdColor: '#6fcf97' };
  const dismissProps = { bdWidth: '2px', bdColor: '#CC2130' };
  const changedProps = { bdWidth: '2px', bdColor: '#f2c94c' };
  const requiredReviewProps = {
    bdWidth: '2px',
    bdColor: '#f2c94c',
    bg: '#f0b51a33'
  };

  const colors = wasChanged
    ? approved
      ? approvedProps
      : dismiss
      ? dismissProps
      : changed
      ? changedProps
      : requiredReviewProps
    : approved
    ? approvedProps
    : dismiss
    ? dismissProps
    : { bdWidth: '1px', bdColor: '#e6e6e6' };

  const CustomSingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div className="flex items-center">
          <span className="mx-2">{flag}</span>
          <div className="w-[2px] h-6 bg-[#E2E7EB] mx-2"></div>
          {props.data.label}
        </div>
      </components.SingleValue>
    );
  };

  return (
    <div
      className={`${styles.container} ${disabled ? styles.disabled : ''} ${
        hasColumns ? styles.hasColumns : ''
      }`}
    >
      <Select
        id={id}
        isDisabled={disabled}
        instanceId={instanceId}
        menuPlacement={menuPlacement}
        isClearable={false}
        menuPortalTarget={menuPortalTarget}
        styles={{
          ...customStyles,
          option: (provided) => ({
            ...provided,
            fontSize: '14px'
          }),
          control: (styles, state) => ({
            ...styles,
            boxShadow: state.isFocused ? 'inset 0 0 0 1px #005c8a' : 0,
            backgroundColor: state.isDisabled
              ? '#eeeeee'
              : colors.bg || 'white',
            borderColor: state.isFocused ? '#005c8a' : colors.bdColor,
            borderWidth: state.isFocused ? '1px' : colors.bdWidth,
            '&:hover': {
              borderColor: '#005c8a'
            },
            minHeight: customHeight
          })
        }}
        placeholder={placeholder}
        closeMenuOnSelect={true}
        options={data}
        onChange={onChange}
        value={value}
        onInputChange={onInputChange}
        onSelectResetsInput={false}
        onBlurResetsInput={false}
        noOptionsMessage={(e) =>
          typeof noOptionsMessage === 'function'
            ? noOptionsMessage(e)
            : noOptionsMessage
        }
        isLoading={isLoading}
        formatOptionLabel={formatOptionLabel}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        isSearchable={isSearchable}
        components={flag && { SingleValue: CustomSingleValue }}
      />
      <div className={`${styles.actions_wrapper}`}>
        {wasChanged && prevValue && (
          <ChangesLog
            showLog={showLog}
            setShowLog={setShowLog}
            prevValue={prevValue}
          />
        )}
        {clearable && value && (
          <div className={styles.clear} onClick={() => onClear()}>
            <Clear />
          </div>
        )}
      </div>
    </div>
  );
}
